@import 'aos/dist/aos.css';
@import '@team-reflect/reflect-editor/dist/styles.css';
@import './tailwind.css';

a {
  color: inherit;
  text-decoration: none;
}

.app-region-drag {
  -webkit-app-region: drag;
}

.btn,
.btn-sm {
  @apply text-lg font-medium inline-flex items-center justify-center border rounded leading-snug transition duration-200 ease-in-out cursor-pointer;
}

.btn {
  @apply px-8 py-4 shadow-lg;
}

.btn-sm {
  @apply px-4 py-2 shadow text-base;
}

.themed-loading-indicator {
  @apply bg-white;
}

@media (prefers-color-scheme: dark) {
  .themed-loading-indicator {
    @apply bg-black;
  }
}

.flip {
  transform: scaleX(-1);
}

.cursor-zoom-out {
  cursor: zoom-out;
}

.animate-list-item-start {
  transform: translateY(100%);
  opacity: 0;
  right: 32px;
  transition: transform 0ms, opacity 0ms;
}

.animate-list-item-complete {
  transform: translateY(0);
  position: absolute;
  opacity: 1;
  bottom: 32px;
  transition: transform 300ms, opacity 300ms;
}

.horizontal-gutter::after {
  content: '';
  position: absolute;
  left: -6px;
  width: 18px;
  display: block;
  height: 100%;
}

/* Increase click area*/
.note-checkbox::before {
  content: '';
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  left: -5px;
  top: -5px;
  background: transparent;
}

.vertical-gutter::after {
  content: '';
  position: absolute;
  top: -6px;
  height: 18px;
  display: block;
  width: 100%;
}

.cursor-col-resize {
  cursor: col-resize;
}

.cursor-row-resize {
  cursor: row-resize;
}

.checkout-preview > div {
  @apply shadow-md bg-white bg-opacity-90 rounded-md;
}
