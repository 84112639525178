@media print {
  @page :footer {
    color: #fff;
  }
  @page :header {
    color: #fff;
  }
  @page {
    margin: 1cm;
    display: block !important;
    print-color-adjust: exact;
    width: auto;
    height: auto;
    overflow: visible;
    position: relative;
  }
  body {
    div:first-child {
      border: none;
    }
  }
  h1 {
    page-break-before: always;
  }
  table {
    page-break-inside: avoid;
  }
  .chapter {
    page-break-after: always;
  }
  div {
    page-break-inside: avoid;
    page-break-after: auto;
  }
  .reflect-editor-attachment-download-icon {
    display: none;
  }
  li.remirror-list-item-with-custom-mark {
    span:first-child {
      .remirror-collapsible-list-item-button {
        top: 10px;
      }

      .remirror-list-item-checkbox {
        top: 4px;
      }
    }
  }
  li span.remirror-list-item-marker-container {
    width: auto;
  }
}

div.dark {
  *::-webkit-scrollbar {
    width: 8px;
  }

  *::-webkit-scrollbar-track {
    @apply bg-dark-black-800 bg-opacity-70;
  }

  *::-webkit-scrollbar-thumb {
    @apply bg-gray-700 rounded-md;

    &:hover {
      @apply bg-gray-600;
    }
  }
}
